import * as hairOilFragranceSlugs from 'constants/hairOilFragrances';

import solsticeImg from 'assets/images/fragrances/fragrance_solstice.jpg';

export const SOLSTICE = {
  name: 'Solstice',
  slug: hairOilFragranceSlugs.SOLSTICE,
  description: '',
  details: 'Vanilla, Caramel, Musk',
  url: solsticeImg,
  imageAlt: 'Prose Solstice swatch',
};
